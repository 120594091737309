import React, { useState, useEffect } from 'react'
import Modal from './Modal';
import AddOutbound from './AddOutbound.js';
import "../styles/UserOutbound.css"
import dataFetch from '../modules/dataFetch';
import { useDispatch, useSelector } from 'react-redux';
import useDataUpdater from '../modules/useDataUpdater';

import {
  replaceUserReplies,
  addUserReply,
  removeUserReplyAtIndex,
  replaceUserReplyAtIndex,
  resetUserReplies,
  deleteMessageAtIndex
} from "./..//modules/redux/userReplySlice.js"


import Paginate from './Paginate';
import Task from './Task';
import ShowTasks from './ShowTasks';
import DeleteOutbound from './DeleteOutbound';
import RemoveEmails from './RemoveEmails';
import SendSingle from './SendSingle.js';
import OutboundEmails from './OutboundEmails.js';
import {

  replaceUserOutboundAtIndex,

} from '../modules/redux/userOutboundSlice';



function UserOutbound() {
  const port = ""

  const { refreshUserOutbounds } = useDataUpdater()
  const { refreshUserEmails } = useDataUpdater()
  const { refreshUserTasks } = useDataUpdater()
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [modalChildren, setModalChildren] = useState(null);


  const user = useSelector(state => state.userData.userData);
  const uOutbounds = useSelector(state => state.userOutbounds.userOutbounds);
  const replies = useSelector(state => state.userReplies.userReplies);
  const uEmails = useSelector(state => state.userEmails.userEmails);
  const uTasks = useSelector(state => state.userTasks.userTasks);

  //let [replies, setReplies] = useState(() => Array(uOutbounds.length).fill([]));

  const [deletingThread, setDeletingThread] = useState(false)
  const [showReplies, setShowReplies] = useState(false)
  const [fetchingReplies, setFetchingReplies] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(-1)
  const [selectedoutbound, setSelectedOutbound] = useState("")



  const [showTooltip, setShowTooltip] = useState(false);

  const toggleTooltip = () => {
    setShowTooltip(prevState => !prevState);
  };

  // useEffect(() => {
  //   setCurrentList(uOutbounds.slice(indexOfFirstItem, indexOfLastItem))
  // }, [uOutbounds])





  // ==================================================
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  let indexOfLastItem = currentPage * itemsPerPage
  let indexOfFirstItem = indexOfLastItem - itemsPerPage
  let [currentlist, setCurrentList] = useState(uOutbounds.slice(indexOfFirstItem, indexOfLastItem))
  let [currentReplylist, setReplyCurrentList] = useState(replies.slice(indexOfFirstItem, indexOfLastItem))

  const paginate = (pageNumber) => {
    setSelectedIndex(-1)
    setCurrentPage(pageNumber); // Update current page state
  };
  const [searchquery, setsearchquery] = useState("")
  const [searching, setsearching] = useState(false)

  useEffect(() => {
    setCurrentList(uOutbounds.slice(indexOfFirstItem, indexOfLastItem));
    setReplyCurrentList(replies.slice(indexOfFirstItem, indexOfLastItem));
  }, [uOutbounds, currentPage]); // Include currentPage in dependency array



  function searchOutbounds() {

    let result = uOutbounds.filter(outbound => { return outbound.outboundName.toLocaleLowerCase().includes(searchquery.toLocaleLowerCase()) })
    // console.log(result)
    setCurrentList(result)
  }

  function searchqueryChange(e) {
    setsearchquery(e.target.value)
    setsearching(true)
    searchOutbounds()

    if ((e.target.value == null) || (e.target.value = "")) {
      stopsearch()

    }
  }

  function stopsearch() {
    setCurrentList(uOutbounds.slice(indexOfFirstItem, indexOfLastItem))
    setsearching(false)
    setsearchquery("")
  }


  function getPreviousSubject(OutboundName) {
    console.log(OutboundName)
    let taskList = uTasks.filter(task => task.outboundName == OutboundName)

    if (taskList.length > 0) {
      let previousTask = taskList[taskList.length - 1]
      return previousTask.taskSubject
    }
    else {
      return ''
    }


  }

  function getuseroutboundemails(outbound) {// NOT USED
    const result = []

    console.log('THE OUTBOUND IS ', outbound)
    // console.log('UEMAILS', uEmails)
    let i = 0


    for (const item of outbound.emailList) {
      console.log("ITEM", item)
      if (item.allocatedEmail == item.sendingFrom) {

        let Email = uEmails.filter(email => email.emailAddress === item.allocatedEmail);
        let previousSubject = getPreviousSubject(outbound.outboundName)
        result.push(
          {
            emailAddress: Email[0].emailAddress,
            password: Email[0].password,
            outboundName: outbound.outboundName,
            previousSubject: previousSubject,
            threads: item.threadIDs


          }
        )
      }
      else {
        let Email = uEmails.filter(email => {
          return email.emailAddress === item.allocatedEmail && email.parentEmail === item.sendingFrom;
        });

        let previousSubject = getPreviousSubject(outbound.outboundName)
        result.push(
          {
            emailAddress: Email[0].parentEmail,
            password: Email[0].password,
            outboundName: outbound.outboundName,
            previousSubject: previousSubject,
            threads: item.threadIDs
          }
        )
      }
    }



    return result
  }

  function getOutboundIndex(outboundName) {
    try {
      for (let i = 0; i < uOutbounds.length; i++) {

        if (uOutbounds[i].outboundName === outboundName) {
          return i
        }
      }
    } catch (error) {
      return false
    }
  }




  function ItemList(props) {
    return (
      <div className='itemList'>

        <ul>
          {
            props.data.map((outbound, index) => (
              <li key={index}>

                <div className='item-value'>
                  <p>{outbound.outboundName}</p>
                </div>
                <div className='item-controls'>
                  <i className="fa-solid fa-circle-plus item-icon add" title='add task' onClick={
                    () => {

                      setModalChildren(<Task
                        data={outbound}

                        openModal={setShowModal} />)
                      setShowModal(true);
                    }}
                  ></i>

                  <i class="fa-solid fa-reply-all" title='remove from email list' onClick={() => {
                    setModalChildren(<RemoveEmails
                      data={outbound}

                      openModal={setShowModal} />)
                    setShowModal(true);
                  }}></i>

                  <i className="fa-solid fa-clipboard-list item-icon list" title='task list' onClick={
                    () => {


                      let outboundTasks = uTasks.filter(task => task.outboundName === outbound.outboundName)
                      setModalChildren(<ShowTasks data={outboundTasks} openModal={setShowModal} />)
                      setShowModal(true);
                    }
                  }></i>

                  <i className="fa-solid fa-trash item-icon delete" title='delete' onClick={
                    () => {
                      setModalChildren(<DeleteOutbound data={outbound} openModal={setShowModal} value="outbound" />)
                      setShowModal(true);
                    }
                  } ></i>


                  {
                    fetchingReplies && index === selectedIndex ? (
                      <i className="fa-solid fa-spinner fa-spin"></i>
                    ) : (
                      <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                        <i
                          className="fa-solid fa-message"
                          style={{ color: "blue" }}
                          title="replies"
                          onClick={async () => {
                            console.log(currentReplylist)
                            let outboundIndex = getOutboundIndex(outbound.outboundName)


                            if (!fetchingReplies) {

                              if (replies[outboundIndex].length != 0) {
                                setSelectedIndex(index);
                                setShowReplies(true)
                              }
                              else {
                                setSelectedIndex(index);
                                setFetchingReplies(true);
                                setSelectedOutbound(outbound.outboundName)
                                let outboundEmailData = getuseroutboundemails(outbound);
                                console.log("OUTBOUNDDATA", outboundEmailData);
                                const url = port + "/getReplies";
                                let request = {
                                  outboundDetails: JSON.stringify(outboundEmailData)
                                };

                                try {
                                  const response = await dataFetch(url, request);
                                  if (response) {
                                    const messages = JSON.parse(response.data)
                                    let messageList = []

                                    messages.forEach(item => {
                                      item.forEach(message => {
                                        messageList.push(message)
                                      })
                                    });

                                    const updatedReplies = [...replies]; // Create a shallow copy of the replies array
                                    updatedReplies[outboundIndex] = messageList; // Modify the copied array

                                    dispatch(replaceUserReplies(updatedReplies))
                                    setFetchingReplies(false);
                                  }
                                }
                                catch (err) {
                                  alert("Error fetching replies. Check Internet Connection")
                                  setSelectedIndex(-1);
                                  setFetchingReplies(false);
                                }
                              }
                            }
                            else {
                              alert("Still fetching replies for " + selectedoutbound)
                            }

                          }}
                        >

                        </i>
                        <p>
                          {(() => {
                            let outboundIndex = getOutboundIndex(outbound.outboundName);
                            if (outboundIndex !== false) {
                              // Check if replies[outboundIndex] exists
                              if (replies[outboundIndex]) {
                                return replies[outboundIndex].length;
                              } else {
                                return 0;
                              }
                            } else {
                              return 0;
                            }
                          })()}
                        </p>


                      </div>
                    )
                  }

                </div>


                {showReplies && index === selectedIndex ?
                  (
                    <div className='outbound-details'>
                      {replies[getOutboundIndex(outbound.outboundName)].map((reply, replyIndex) => (
                        <details key={replyIndex} >

                          <summary className='outbound-detail-summary'>{reply.subject}</summary>
                          <div className='individaul-messages'>


                            <div className='reply-date'>
                              <p>{reply.date}</p>
                            </div>
                            <div className='reply-from'>
                              <p className='lab'>From:</p>
                              <div className='reply-content'>{reply.from}</div>
                            </div>
                            <div className='reply-to'>
                              <p className='lab'>To:</p>
                              <div className='reply-content'>
                                {reply.email}
                              </div>
                            </div>
                            <div className='reply-body'>
                              <p className='lab'>Body:</p>
                              <div className='reply-content'>{reply.body.length > 200 ? reply.body.substring(0, 200) + "..." : reply.body}</div>
                            </div>
                            <div className='reply-controls'>
                              <div><i class="fa-solid fa-reply-all"></i></div>
                              <div onClick={async () => {
                                setDeletingThread(true)
                                const url = port + "/deletethread";
                                let emailsToDelete = [];

                                let request = {
                                  outboundName: outbound.outboundName,
                                  email: reply.email,
                                  password: reply.password,
                                  messageId: reply.messageId,
                                  inReplyTo: reply.inReplyTo,
                                };
                                let result = await dataFetch(url, request)
                                if (result.message == "deleted") {
                                  console.log("deleted")
                                  let indextoreplace = getOutboundIndex(outbound.outboundName)
                                  console.log("THE INDEX TO REPLACE IS", indextoreplace)
                                  dispatch(replaceUserOutboundAtIndex({ index: indextoreplace, newUserOutbound: result.data }));

                                  // let nestedArray = replies[indextoreplace];
                                  // console.log(nestedArray, replyIndex)
                                  // nestedArray.splice(replyIndex, 1)
                                  // console.log(indextoreplace)
                                  dispatch(deleteMessageAtIndex({ index: indextoreplace, replyIndex: replyIndex }))
                                  setDeletingThread(false)
                                }
                                else {
                                  console.log("error occured")
                                  setDeletingThread(false)
                                }
                              }}>
                                {
                                  deletingThread ?
                                    (<i class="fa-solid fa-spinner fa-spin"></i>) :

                                    (<i class="fa-solid fa-trash"></i>)
                                }


                              </div>
                            </div>









                          </div>
                        </details>
                      ))}

                      <div className='reply-controls reply-refresh'>
                        <div onClick={async () => {
                          setSelectedIndex(index);
                          setFetchingReplies(true);
                          setSelectedOutbound(outbound.outboundName)
                          let outboundEmailData = getuseroutboundemails(outbound);
                          console.log("OUTBOUNDDATA", outboundEmailData);
                          const url = port + "/getReplies";
                          let request = {
                            outboundDetails: JSON.stringify(outboundEmailData)
                          };

                          try {
                            const response = await dataFetch(url, request);
                            if (response) {
                              const messages = JSON.parse(response.data)
                              let messageList = []

                              messages.forEach(item => {
                                item.forEach(message => {
                                  messageList.push(message)
                                })
                              });
                              const outboundIndex = getOutboundIndex(outbound.outboundName);

                              dispatch(replaceUserReplyAtIndex({ index: outboundIndex, newUserReply: messageList }))
                              setFetchingReplies(false);
                            }
                          }
                          catch (err) {
                            console.log(err)
                          }
                        }}>{fetchingReplies ? (<i class="fa-solid fa-spinner fa-spin"></i>) : (<p>refresh</p>)}</div>
                      </div>
                    </div>
                  )

                  :

                  (
                    <details className='outbound-details'>
                      <summary className='outbound-detail-summary'>See more </summary>
                      <div className='outbound-email-details'>
                        <div className='outbound-allocated-email'><b>Allocated Emails</b></div>
                        <div className='outbound-allocated-email-capacity'><b>Alloc.</b></div>
                      </div>
                      <div>
                        {outbound.emailList.map((item, index) => (
                          <div key={index} className='outbound-email-details'>

                            <div className='outbound-allocated-email'>{item.allocatedEmail !== item.sendingFrom ? (<p>{item.allocatedEmail}<br /><b>{item.sendingFrom}</b></p>) : (<p>{item.allocatedEmail}</p>)}</div>
                            <div className='outbound-allocated-email-capacity'><b>{item.emailAllocations.length}</b></div>
                            <div></div>
                          </div>
                        ))}
                      </div>


                    </details>
                  )

                }





              </li>
            ))
          }
        </ul>

      </div>
    )
  }

  return (
    <div className='tab-content-container'>
      {/* <div className='tab-content-container-header'>
        <h1>Out Bounds</h1>
      </div> */}


      <div className='tab-content-container-contents'>
        <div className='email-display-options'>

          <div className='add-Email' onClick={() => {
            setModalChildren(<AddOutbound openModal={setShowModal} />)
            setShowModal(true);

          }}>
            <p>+</p>
            <p>New Outbound</p>
          </div>

          <div className='email-data'>
            <h3>Outbound Data</h3>
            <div>
              <p><b>Outbounds:</b> {uOutbounds.length}</p>

            </div>
          </div>

          <div className='add-Email' onClick={() => {
            setModalChildren(<SendSingle openModal={setShowModal} />)
            setShowModal(true);

          }}>
            <i class="fa-solid fa-envelope-open-text"></i>
            <p>Single Email</p>
          </div>

        </div>

        <div className='email-table'>
          <div className='search-container'>
            <input
              className='searchinput'
              type='text'
              value={searchquery}
              placeholder='...search'
              onChange={searchqueryChange}
            />
            {searching ? (<i class="fa-solid fa-circle-xmark search-button" onClick={stopsearch}></i>) : (<i class="fa-solid fa-magnifying-glass search-button" onClick={() => {
              setsearching(true)
              searchOutbounds()
            }}></i>)}
          </div>

          <ItemList data={currentlist} />

        </div>


        {searching ? (<></>) : (<Paginate itemsPerPage={itemsPerPage} totalItemsCount={uOutbounds.length} paginate={paginate} />)}
      </div>
      {showModal ? (<Modal header="Add Account" children={modalChildren} show={setShowModal}></Modal>) : (<></>)}
    </div>
  )
}

export default UserOutbound