// slice.js
import { createSlice } from '@reduxjs/toolkit';


// Define initial state
const initialState = {
    userOutbounds: [],
};

// Create slice
const userOutboundSlice = createSlice({
    name: 'userOutbounds',
    initialState,
    reducers: {
        // Reducer to replace the initial state
        replaceUserOutbounds: (state, action) => {
            state.userOutbounds = action.payload;
        },
        // Reducer to add an item to the state
        addUserOutbound: (state, action) => {
            state.userOutbounds.push(action.payload);
        },
        // Reducer to remove an item of a particular index from the state
        removeUserOutboundAtIndex: (state, action) => {
            const index = action.payload;
            state.userOutbounds.splice(index, 1);
        },
        // Reducer to replace an item in a particular index
        replaceUserOutboundAtIndex: (state, action) => {
            const { index, newUserOutbound } = action.payload;
             state.userOutbounds[index] = newUserOutbound;
           
        },
        // Reducer to set the array back to the initial state
        resetUserOutbounds: (state) => {
            state.userOutbounds = initialState.userOutbounds;
        },
    },
});

// Export actions and reducer
export const { replaceUserOutbounds, addUserOutbound, removeUserOutboundAtIndex, replaceUserOutboundAtIndex, resetUserOutbounds } = userOutboundSlice.actions;

export default userOutboundSlice.reducer;