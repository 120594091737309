// slice.js
import { createSlice } from '@reduxjs/toolkit';

// Define initial state
const initialState = {
    userLists: [],
};

// Create slice
const userListSlice = createSlice({
    name: 'userLists',
    initialState,
    reducers: {
        // Reducer to replace the initial state
        replaceUserLists: (state, action) => {
            state.userLists = action.payload;
        },
        // Reducer to add an item to the state
        addUserList: (state, action) => {
            state.userLists.push(action.payload);
        },
        // Reducer to remove an item of a particular index from the state
        removeUserListAtIndex: (state, action) => {
            const index = action.payload;
            state.userLists.splice(index, 1);
        },
        // Reducer to replace an item in a particular index
        replaceUserListAtIndex: (state, action) => {
            const { index, newUserList } = action.payload;
            state.userLists[index] = newUserList;
        },
        // Reducer to set the array back to the initial state
        resetUserLists: (state) => {
            state.userLists = initialState.userLists;
        },
    },
});

// Export actions and reducer
export const { replaceUserLists, addUserList, removeUserListAtIndex, replaceUserListAtIndex, resetUserLists } = userListSlice.actions;

export default userListSlice.reducer;
