import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import userOutboundReducer from './userOutboundSlice';
import userRepliesReducer from './userReplySlice';
import userEmailReducer from './userEmailSlice';
import userListReducer from './userListSlice';
import userTaskReducer from './userTaskSlice';
import UserDataReducer from "./userDataSlice"



// Combine all slice reducers
const rootReducer = combineReducers({
    userData: UserDataReducer,
    userOutbounds: userOutboundReducer,
    userEmails: userEmailReducer,
    userLists: userListReducer,
    userTasks: userTaskReducer,
    userReplies: userRepliesReducer,
});

// Configure Redux Persist
const persistConfig = {
    key: 'letsoutbound',
    storage,
};

// Create persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create Redux store
export const store = configureStore({
    reducer: persistedReducer,
});

// Create persisted store
export const persistor = persistStore(store);
