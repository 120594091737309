// slice.js
import { createSlice } from '@reduxjs/toolkit';


// Define initial state
const initialState = {
    userReplies: [],
};

// Create slice
const userReplySlice = createSlice({
    name: 'userReplies',
    initialState,
    reducers: {
        // Reducer to replace the initial state
        replaceUserReplies: (state, action) => {
            state.userReplies = action.payload;
        },
        // Reducer to add an item to the state
        addUserReply: (state, action) => {
            state.userReplies.push(action.payload);
        },
        // Reducer to remove an item of a particular index from the state
        removeUserReplyAtIndex: (state, action) => {
            const index = action.payload;
            state.userReplies.splice(index, 1);
        },
        // Reducer to replace an item in a particular index
        replaceUserReplyAtIndex: (state, action) => {
            const { index, newUserReply } = action.payload;
            state.userReplies[index] = newUserReply;

        },
        deleteMessageAtIndex: (state, action) => {
            const { index, replyIndex } = action.payload;
            const currentReplylist = [...state.userReplies[index]]; // Make a copy of the array to avoid mutating the state directly
            console.log('current reply list =', currentReplylist);
            currentReplylist.splice(replyIndex, 1);
            console.log('current reply list =', currentReplylist);
            state.userReplies[index] = currentReplylist;
        },
        
        // Reducer to set the array back to the initial state
        resetUserReplies: (state) => {
            state.userReplies = initialState.userReplies;
        },
    },
});

// Export actions and reducer
export const { replaceUserReplies, addUserReply, removeUserReplyAtIndex, replaceUserReplyAtIndex, resetUserReplies,deleteMessageAtIndex } = userReplySlice.actions;

export default userReplySlice.reducer;