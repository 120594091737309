// slice.js
import { createSlice } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// Define initial state
const initialState = {
    userTasks: [],
};

// Create slice
const userTaskSlice = createSlice({
    name: 'userTasks',
    initialState,
    reducers: {
        // Reducer to replace the initial state
        replaceUserTasks: (state, action) => {
            state.userTasks = action.payload;
        },
        // Reducer to add an item to the state
        addUserTask: (state, action) => {
            state.userTasks.push(action.payload);
        },
        // Reducer to remove an item of a particular index from the state
        removeUserTaskAtIndex: (state, action) => {
            const index = action.payload;
            state.userTasks.splice(index, 1);
        },
        // Reducer to replace an item in a particular index
        replaceUserTaskAtIndex: (state, action) => {
            const { index, newUserTask } = action.payload;
            state.userTasks[index] = newUserTask;
        },
        // Reducer to set the array back to the initial state
        resetUserTasks: (state) => {
            state.userTasks = initialState.userTasks;
        },
    },
});

// Export actions and reducer
export const { replaceUserTasks, addUserTask, removeUserTaskAtIndex, replaceUserTaskAtIndex, resetUserTasks } = userTaskSlice.actions;

export default userTaskSlice.reducer;
