import { useState } from 'react';
import dataFetch from './dataFetch';
import { useDispatch, useSelector } from 'react-redux';
import { login } from './redux/userDataSlice';
import {
    replaceUserOutbounds,
    addUserOutbound,
    removeUserOutboundAtIndex,
    replaceUserOutboundAtIndex,
    resetUserOutbounds,
} from '../modules/redux/userOutboundSlice';
import {
    replaceUserReplies,
} from '../modules/redux/userReplySlice';
import {
    replaceUserEmails,
    addUserEmail,
    removeUserEmailAtIndex,
    replaceUserEmailAtIndex,
    resetUserEmails,
} from '../modules/redux/userEmailSlice';
import {
    replaceUserTasks,
    addUserTask,
    removeUserTaskAtIndex,
    replaceUserTaskAtIndex,
    resetUserTasks,
} from '../modules/redux/userTaskSlice';
import {
    replaceUserLists,
    addUserList,
    removeUserListAtIndex,
    replaceUserListAtIndex,
    resetUserLists,
} from '../modules/redux/userListSlice';

const useDataUpdater = () => {

    const user = useSelector(state => state.userData.userData);
    const uEmails = useSelector((state) => state.userEmails.userEmails);
    const dispatch = useDispatch();
    const port = ""

    async function refreshUserOutbounds(requestData) {
        let url = port + '/getuseroutbounds'
        const result = await dataFetch(url, requestData)
        if (result.message === "outbounds-found") {
            const userOutbounds = result.data;

            dispatch(replaceUserOutbounds(userOutbounds))
            console.log("outbound refreeshed")
            return true;
        }

    }
    async function refreshReplies(requestData) {
        let url = port + '/getuseroutbounds'
        const result = await dataFetch(url, requestData)
        if (result.message === "outbounds-found") {
            const userOutbounds = result.data;
            const filledArray = Array(userOutbounds.length).fill([])
            dispatch(replaceUserReplies(filledArray))
            console.log("outbound refreeshed")
            return true;
        }

    }
    async function refreshUserEmails(requestData) {
        let url = port + '/getuseroutboundemails'
        const result = await dataFetch(url, requestData)
        if (result.message === "emails-found") {
            const userEmails = result.data;
            // console.log('USER EMAILS:', result.data)
            dispatch(replaceUserEmails(userEmails))
            return true;
        }

    }
    async function refreshUserTasks(requestData) {
        let url = port + '/getusertasks'
        const result = await dataFetch(url, requestData)
        if (result) {
            const userTasks = result.data
            dispatch(replaceUserTasks(userTasks))
            return true;
        }

    }

    // async function refreshUserScraping(requestData) {
    //     //  IM JUST USING THE USER TASK TO HOLD FROM ERROR.. THIS FUNCTION IS DEPRECIATED
    //     let url = port + '/getuserscrapings'
    //     const result = await dataFetch(url, requestData)
    //     if (result) {
    //         const userScrapings = result.data
    //         console.log(result.data)
    //         dispatch(replaceUserTasks({
    //             scrapings: userScrapings
    //         }))
    //         return true;
    //     }

    // }


    return {
        refreshUserOutbounds,
        refreshUserEmails,
        refreshUserTasks,
        refreshReplies,
        // refreshUserScraping
    }
}


export default useDataUpdater;