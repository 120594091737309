// slice.js
import { createSlice } from '@reduxjs/toolkit';


// Define initial state
const initialState = {
    userEmails: [],
};

// Create slice
const userEmailSlice = createSlice({
    name: 'userEmails',
    initialState,
    reducers: {
        // Reducer to replace the initial state
        replaceUserEmails: (state, action) => {
            state.userEmails = action.payload;
        },
        // Reducer to add an item to the state
        addUserEmail: (state, action) => {
            state.userEmails.push(action.payload);
        },
        // Reducer to remove an item of a particular index from the state
        removeUserEmailAtIndex: (state, action) => {
            const index = action.payload;
            state.userEmails.splice(index, 1);
        },
        // Reducer to replace an item in a particular index
        replaceUserEmailAtIndex: (state, action) => {
            const { index, newUserEmail } = action.payload;
            state.userEmails[index] = newUserEmail;
        },
        // Reducer to set the array back to the initial state
        resetUserEmails: (state) => {
            state.userEmails = initialState.userEmails;
        },
    },
});

// Export actions and reducer
export const { replaceUserEmails, addUserEmail, removeUserEmailAtIndex, replaceUserEmailAtIndex, resetUserEmails } = userEmailSlice.actions;

export default userEmailSlice.reducer;
